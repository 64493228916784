import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import PercentErrorCalculator from '../../tools/PercentErrorCalculator'

const PercentErrorCalculatorPage = () => (
    <Layout>
        <SEO title="Percent Error Calculator – Calculate Error Percentage Online" />

        <section className="hero is-primary">
            <div className="hero-body">
                <div className="container is-fluid">
                    <h1 className="title">Percent Error Calculator</h1>
                    <h2 className="subtitle">
                        Simple tool to calculate percent error values between the <strong>true value</strong> and the <strong>observed value</strong>.
                    </h2>
                </div>
            </div>
        </section>

		<section className="section">
			<div className="container">
				<PercentErrorCalculator />
			</div>
		</section>

    </Layout>
)

export default PercentErrorCalculatorPage
